
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import {
  IonIcon,
  IonSlides,
  IonSlide,
  IonButton,
  IonContent,
  IonLabel,
  IonCheckbox,
  IonRow,
  IonCol,
  IonGrid,
  IonApp,
  IonImg,
} from '@ionic/vue';
import { addIcons } from 'ionicons';
import { arrowForward, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
addIcons({
  'arrow-forward': arrowForward,
  'eye-off-outline': eyeOffOutline,
  'eye-outline': eyeOutline,
});
import { useCookie } from '@vue-composable/cookie';
export default {
  name: 'Intro',
  components: {
    IonContent,
    IonSlides,
    IonSlide,
    IonIcon,
    IonButton,
    IonApp,
    IonRow,
    IonCol,
    IonGrid,
    IonImg,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const hideIntro = ref(false);
    const isAndroid = ref(true);

    const hideIntroCookie = useCookie('hide-intro', '', {
      expires: new Date(9999, 12, 30),
    });

    onBeforeMount(() => {
      //const { cookie } = useCookie('hideIntro');
      if (!hideIntroCookie.cookie.value) {
        hideIntroCookie.setCookie('false', {
          expires: new Date('9999-12-31T23:59:59.999Z'),
        });
      }
      hideIntro.value = hideIntroCookie.cookie.value == 'true';
      if (hideIntro.value) {
        router.push('/main/home');
      }
      //hideIntro.value = hideIntroCookie.cookie.value == 'true';
      isAndroid.value = navigator.userAgent.indexOf('Android') > 0;
    });

    const show = () => {
      hideIntro.value = false;
      hideIntroCookie.setCookie('false', {
        expires: new Date('9999-12-31T23:59:59.999Z'),
      });
    };

    const hide = () => {
      hideIntro.value = true;
      hideIntroCookie.setCookie('true', {
        expires: new Date('9999-12-31T23:59:59.999Z'),
      });
    };

    const goToHome = async () => {
      const { cookie, removeCookie } = useCookie('hideIntro');
      if (hideIntro.value) {
        cookie.value = String(hideIntro.value);
      } else {
        removeCookie();
      }
      router.push('/main/home');
    };

    return {
      arrowForward,
      hideIntro,
      goToHome,
      isAndroid,
      hide,
      show,
    };
  },
};
