<template>
  <ion-app>
    <ion-content fullscreen scroll-y="false">
      <ion-slides pager="false">
        <ion-slide class="ion-padding">
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <img style="width: 140px;" src="/img/icons/android-192x192.png" />
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <h2 color="secondary"><b>Benvenuti!</b></h2>
                <p color="secondary">
                  L’innovativo sistema informativo che permette al professionista di
                  essere sempre aggiornato
                </p>
              </ion-col>
            </ion-row>
            <ion-grid v-if="!isAndroid">
              <ion-row>
                <ion-col size="12">
                  <div>Scarica l'app di JuraNews sul tuo smartphone!</div>
                </ion-col>
                <ion-col size="12" class="ion-margin-top">
                  <div>
                    Clicca sull'icona
                    <ion-img class="ion-margin-top ion-margin-bottom" style="
                      height: 30px !important;
                      width: auto !important;
                      align-items: center;
                    " src="img/iphone-share.jpg" />e seleziona la voce
                  </div>
                </ion-col>
                <ion-col size="12" class="ion-margin-top">"Aggiungi alla schermata Home"
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" class="ion-padding-top ion-margin-top">
                  <ion-button fill="clear" color="secondary" @click="goToHome">
                    ACCEDI
                    <ion-icon color="secondary" slot="end" name="arrow-forward" />
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <ion-button fill="clear" v-if="!hideIntro" @click="hide">
                    Non mostrare più
                    <ion-icon slot="start" name="eye-off-outline" />
                  </ion-button>
                  <ion-button fill="clear" v-if="hideIntro" @click="show">
                    Mostra ancora
                    <ion-icon slot="start" name="eye-outline" />
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid v-if="isAndroid">
              <ion-row>
                <ion-col size="12">
                  <div>Scarica l'app di JuraNews sul tuo smartphone!</div>
                </ion-col>
                <ion-col size="12" class="ion-margin-top">
                  <div>
                    Clicca sul banner che appare in fondo alla pagina oppure
                    seleziona
                  </div>
                </ion-col>
                <ion-col size="12" class="ion-margin-top">
                  <div>"Installa app"</div>
                </ion-col>
                <ion-col size="12" class="ion-margin-top">
                  <div>dal menu in alto a destra</div>
                </ion-col>
              </ion-row>
              <ion-row class="ion-margin-top ion-padding-top">
                <ion-col size="12">
                  <ion-button fill="solid" color="secondary" @click="goToHome">
                    ACCEDI
                    <ion-icon slot="end" name="arrow-forward" />
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12">
                  <ion-button fill="clear" v-if="!hideIntro" @click="hide">
                    Non mostrare più
                    <ion-icon slot="start" name="eye-off-outline" />
                  </ion-button>
                  <ion-button fill="clear" v-if="hideIntro" @click="show">
                    Mostra ancora
                    <ion-icon slot="start" name="eye-outline" />
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-grid>

        </ion-slide>
      </ion-slides>
    </ion-content>
  </ion-app>
</template>

<script lang="ts">
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import {
  IonIcon,
  IonSlides,
  IonSlide,
  IonButton,
  IonContent,
  IonLabel,
  IonCheckbox,
  IonRow,
  IonCol,
  IonGrid,
  IonApp,
  IonImg,
} from '@ionic/vue';
import { addIcons } from 'ionicons';
import { arrowForward, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
addIcons({
  'arrow-forward': arrowForward,
  'eye-off-outline': eyeOffOutline,
  'eye-outline': eyeOutline,
});
import { useCookie } from '@vue-composable/cookie';
export default {
  name: 'Intro',
  components: {
    IonContent,
    IonSlides,
    IonSlide,
    IonIcon,
    IonButton,
    IonApp,
    IonRow,
    IonCol,
    IonGrid,
    IonImg,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const hideIntro = ref(false);
    const isAndroid = ref(true);

    const hideIntroCookie = useCookie('hide-intro', '', {
      expires: new Date(9999, 12, 30),
    });

    onBeforeMount(() => {
      //const { cookie } = useCookie('hideIntro');
      if (!hideIntroCookie.cookie.value) {
        hideIntroCookie.setCookie('false', {
          expires: new Date('9999-12-31T23:59:59.999Z'),
        });
      }
      hideIntro.value = hideIntroCookie.cookie.value == 'true';
      if (hideIntro.value) {
        router.push('/main/home');
      }
      //hideIntro.value = hideIntroCookie.cookie.value == 'true';
      isAndroid.value = navigator.userAgent.indexOf('Android') > 0;
    });

    const show = () => {
      hideIntro.value = false;
      hideIntroCookie.setCookie('false', {
        expires: new Date('9999-12-31T23:59:59.999Z'),
      });
    };

    const hide = () => {
      hideIntro.value = true;
      hideIntroCookie.setCookie('true', {
        expires: new Date('9999-12-31T23:59:59.999Z'),
      });
    };

    const goToHome = async () => {
      const { cookie, removeCookie } = useCookie('hideIntro');
      if (hideIntro.value) {
        cookie.value = String(hideIntro.value);
      } else {
        removeCookie();
      }
      router.push('/main/home');
    };

    return {
      arrowForward,
      hideIntro,
      goToHome,
      isAndroid,
      hide,
      show,
    };
  },
};
</script>
